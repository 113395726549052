import { styled } from '@mui/material/styles';

import theme from '@/styles/theme';

const colors = {
  blue: {
    backgroundColor: theme.palette.primaryColorShades.p8,
    color: theme.palette.primary.main,
  },
  gray: {
    backgroundColor: theme.palette.grayscale[100],
    color: theme.palette.grayscale[600],
  },
  errorLight: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
};

const sizes = {
  medium: {
    fontSize: theme.typography.pxToRem(14),
    height: 28,
    padding: theme.spacing(0, 2),
    borderRadius: 14,
  },
  small: {
    fontSize: theme.typography.pxToRem(12),
    height: 24,
    padding: theme.spacing(0, 1),
    borderRadius: 12,
  },
};

export const ChipWrapper = styled('div', {
  shouldForwardProp: prop => !['color', 'size', 'uppercase'].includes(prop),
})(({ color, size, uppercase, theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.typography.captionRedesign,
  lineHeight: 1,
  ...colors[color],
  ...sizes[size],
  ...(uppercase && {
    textTransform: 'uppercase',
    padding: theme.spacing(0, 2.25),
  }),
}));
